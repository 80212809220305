.base-toaster {
  position: fixed;
  left: 1rem;
  right: 1rem;
  top: 8rem;
  z-index: 9999;
}

.toast {
  background: $primary;
  width: 100%;
  max-width: 450px;
  margin: auto;
  color: white;
  font-weight: bold;
  border-radius: 0.5rem;
  animation: fadeInDownToast 500ms ease;
  .toast-body {
    padding: 1rem 2rem;
  }
}
