.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: white;
}

.btn-danger, .btn-danger:hover, .btn-danger:focus, .btn-danger:active {
  color: white;
}

.btn-info, .btn-info:hover, .btn-info:focus, .btn-info:active {
  color: white;
}

.btn-light {
  @include themed() {
    background: t("light-bg");
  };
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    border-color: transparent;
  }
  .btn-#{$color}:hover {
    border-color: transparent;
  }
}

.rounded-btn {
  border: 2px solid white;
  transition: 200ms;
  border-radius: $border-radius;
  &:hover {
    @include themed() {
      background: t($bg);
    };
    a {
      color: #174984;
    }
  }
}

.btn-icon {
  border-radius: $border-radius-pill;
  padding: 0 !important;
  width: 45px;
  font-size: $font-size-lg;
  height: 45px;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  @extend .row-center;
  transition: 300ms;
  &:hover {
    background-color: rgba($dark, 0.1);
  }
  &.btn-icon-disabled {
    color: rgba(white, 0.5);
    cursor: auto;
    &:hover {
      background-color: transparent;
    }
  }
}

.small-btn-icon {
  border-radius: 0.3rem;
  padding: 0 !important;
  width: 32px;
  height: 32px;
  @include themed() {
    background: t($light-bg);
    color: t($navbar-text);
  };
  cursor: pointer;
  outline: 0;
  line-height: 32px;
  text-align: center;
  transition: 300ms;
  &:hover {
    background-color: $primary;
    color: white;
  }
}
