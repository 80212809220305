.form-control {
  @include themed() {
    background-color: t($light-bg) !important;
  }
  color: inherit;
  border: none;
}

.icon-input {
  position: relative;
  &.icon-input-left {
    .form-control {
      padding-left: 3rem !important;
    }
    .left-icon {
      left: 1rem;
    }
  }
  &.icon-input-right {
    .form-control {
      padding-right: 3rem !important;
    }
    .right-icon {
      right: 1rem;
    }
  }
  .icon {
    transition: 400ms;
    position: absolute;
    font-size: 18px;
    color: $gray-700;
    top: 50%;
    transform: translateY(-50%);
  }
}

.select-design {
  position: relative;
  width: 100%;
  .icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    color: $gray;
    transform: translateY(-50%);
  }
  input {
    padding: $input-padding-y $input-padding-x $input-padding-y 3rem;
    border: none !important;
    &:focus {
      border: 0 !important;
    }
  }
  .select-button {
    position: relative;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    cursor: pointer;
    display: flex;
    border-radius: $border-radius-sm;
    @include themed() {
      background: t($light-bg);
      color: t($text)
    };
    .selected-option {
      position: relative;
      width: 100%;
      padding: 0 1.5rem 0 0.5rem;
    }
    .add-btn-select {
      width: 40px;
      min-width: 40px;
      text-align: center;
      border-radius: 0.5rem;
      margin: 4px;
    }
    &:hover {
      @include themed() {
        background: t($light-bg);
      };
    }
    &.open {
      padding: 0;
      &:hover {
        @include themed() {
          background: t($bg);
        };
      }
    }
    .caret {
      position: absolute;
      top: 50%;
      color: $gray;
      right: 0.5rem;
      transform: translateY(-50%);
      font-size: 1.1rem;
    }
    .select-icon {
      width: 2rem;
      height: 2rem;
      background-color: $primary;
      display: flex;
      border-radius: $border-radius;
      margin-left: -0.5rem;
      color: white;
      font-size: 1.1rem;
      &-blue {
        @extend .select-icon;
        background-color: $blue;
      }
      svg {
        margin: auto;
      }
    }
    .select-title {
      font-weight: 600;
      margin: auto;
      color: $gray-400;
      padding: 0 1rem;
    }
  }
  .select-options {
    max-height: 400px;
    overflow: auto;
    box-shadow: $shadow;
    padding: 0.5rem 0;
    position: absolute;
    z-index: 10;
    @include themed() {
      background: t($front-bg);
    };
    width: 100%;
  }
  .option {
    cursor: pointer;
    padding: 0.5rem 1rem;
    &:hover {
      @include themed() {
        background: t($light-bg);
      };
    }
    &.selected {
      cursor: auto;
      background-color: rgba($primary, 0.3);
    }
  }
}


.is-focus {
  transition: 400ms;
  color: $primary;
}

.integer-input {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: $border-radius-sm;
  input {
    text-align: center;
    height: $input-height;
    font-size: $font-size-lg;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    flex: 1;
    outline: 0;
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }
  .integer-button {
    &.disable {
      color: $secondary;
    }
  }
  &.integer-input-xs {
    input {
      height: 35px;
      min-width: 38px;
      width: 38px;
      padding: 0;
    }
    button {
      height: 35px;
      width: 35px;
      font-size: 1rem;
    }
  }
  &.integer-input-sm {
    input {
      height: 45px;
      min-width: 38px;
      width: 38px;
    }
    button {
      height: 45px;
      width: 35px;
      font-size: 1rem;
    }
  }
  &.integer-input-lg {
    input {
      height: 45px;
      min-width: 35px;
      width: 35px;
    }
    button {
      height: 45px;
      width: 35px;
      font-size: 1rem;
    }
  }
  &.integer-input-xl {
    input, button {
      height: 55px;
      min-width: 55px;
      width: 55px;
    }
  }
}

// CHECKBOX
.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  i {
    line-height: 13px;
    font-size: 0.8rem;
    color: white;
  }
}
.cbx:not(:last-child) {
  margin-right: 6px;
}
.cbx:hover {
  background: rgba(0,119,255,0.06);
}
.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0,16,75,0.05);
}
.cbx span:first-child i {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.cbx:hover span:first-child {
  border-color: $primary;
}
.inp-cbx {
  position: absolute;
  visibility: hidden;
}
.inp-cbx:checked + .cbx span:first-child {
  background: $primary;
  border-color: $primary;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child i {
  stroke-dashoffset: 0;
}
.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

// RANGE INPUT

@mixin rangeThumb {
  width: 18px;
  height: 18px;
  margin: -8px 0  0;
  border-radius: $border-radius-pill;
  background: $primary;
  cursor: pointer;
  border: 0 !important;
}

@mixin rangeTrack {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: $gray-300;
}

.range {
  position: relative;
  height: 5px;
  width: 100%;
}

.range input {
  width: 100%;
  position: absolute;
  top: 2px;
  height: 0;
  -webkit-appearance: none;

  // Thumb
  &::-webkit-slider-thumb {
    -webkit-appearance: none; // needed again for Chrome & Safari
    @include rangeThumb;
  }

  &::-moz-range-thumb {
    @include rangeThumb;
  }

  &::-ms-thumb {
    @include rangeThumb;
  }

  // Track
  &::-webkit-slider-runnable-track {
    @include rangeTrack;
  }

  &::-moz-range-track {
    @include rangeTrack;
  }

  &::-ms-track {
    @include rangeTrack;
  }

  &:focus { // override outline/background on focus
    background: none;
    outline: none;
  }

  &::-ms-track { // A little somethin' somethin' for IE
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

// Labels below slider
.range-labels {
  margin: 0 -11px;
  div {
    position: relative;
    margin-top: 14px;
    width: 30px;
    text-align: center;
    color: $gray;
    &::before {
      position: absolute;
      top: -21px;
      right: 0;
      left: 0;
      content: "";
      margin: 0 auto;
      width: 9px;
      height: 9px;
      background: $gray-300;
      border-radius: $border-radius-pill;
    }
  }

  .active {
    color: $primary;
  }

  .selected::before {
    background: $primary;
  }

  .active.selected::before {
    display: none;
  }
}


input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.check-trail {
  display: flex;
  align-items: center;
  width: 2.55rem;
  height: 1.55rem;
  margin-bottom: 0;
  @include themed() {
    background: t($light-bg);
  };
  border-radius: 2.5em;
  transition: $transition-base;
  cursor: pointer;
}
.check-handler {
  display: flex;
  margin-left: 0.125rem;
  justify-content: center;
  align-items: center;
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
  transition: $transition-base;
  @include themed() {
    background: t($front-bg);
  };
}
input[type="checkbox"]:checked + .check-trail {
  background-color: rgba($primary, 0.2);
  .check-handler {
    border: 1px solid transparent;
    background-color: $primary;
    margin-left: calc(50% - 0.2rem);
  }
}

.smart-switch {
  @extend .row-flex;
  .switch-label {
    margin-left: 0.75rem;
  }
  &.smart-switch-primary {
    input[type="checkbox"]:checked + .check-trail {
      border: 1px solid transparent;
      background: rgba($blue, 1);
    }
  }
}

.input-range {
  height: 5rem !important;
  margin-bottom: 1rem;
  .input-range__track--active {
    background-color: $primary;
  }
  .input-range__slider {
    background-color: $primary;
    border-color: $primary;
  }
  .input-range__label-container {
    font-size: $font-size-base;
  }
  .input-range__label.input-range__label--value {
    color: $primary;
    font-weight: bold;
  }
  .input-range__label--min, .input-range__label--max {
    bottom: 0;
  }
}


.date-range-dropdown {
  overflow: auto;
  box-shadow: $shadow;
  padding: 0.5rem 0;
  position: absolute;
  // transform: translateX(-50%);
  z-index: 10;
  background: white;
  width: 630px;
}

.DayPickerInput {
  width: 100%;
  input {
    width: 100%;
    height: $height-element;
  }
}
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba($primary, 0.1) !important;
  color: $primary;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $primary !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.DayPicker-range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba($primary, 0.1) !important;
  color: $primary;
}
.DayPicker-range .DayPicker-Day {
  border-radius: 0 !important;
}
.DayPicker-Caption {
  text-transform: capitalize;
}