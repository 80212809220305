
.godin-logo {
  font-size: 1.5rem;
  background: -webkit-linear-gradient(#7ec9d1, #0b4d8d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &.godin-logo-lg {
    font-size: 3rem;
  }
}

.band1 {
  background-color: #4d9cc140;
  height: 2000px;
  width: 18px;
  position: fixed;
  left: 675px;
  bottom: -549px;
  z-index: -1;
  transform: rotate(56deg);
}
.band2 {
  background-color: white;
  height: 2000px;
  width: 18px;
  position: fixed;
  left: 625px;
  bottom: -549px;
  z-index: -1;
  transform: rotate(56deg);
}


.head-up {
  height: 100px;
}

.rooms-area {
  height: 100%;
}

.visio-area {
  height: 100%;
}

.messages-area {
  background-color: $gray-200;
  border-radius: $border-radius;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.messages-area-client {
    @include themed() {
      background: t($bg);
    };
  }
  .messages-list {
    height: 100%;
    overflow: auto;
    margin: 0 1rem;
    padding: 1rem 0;
  }
  .bottom-chat-container {
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    z-index: 99;
    .bottom-chat {
      @include themed() {
      background: t($bg);
    };
      position: relative;
      border-radius: $border-radius;
      padding: 0.5rem 1rem;
      box-shadow: $box-shadow;
      textarea {
        border: 0;
        background-color: transparent;
        resize: none;
      }
      .actions-btn {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        @extend .row-end;
        .btn-icon {
          color: $gray-400;
        }
        .send-button {
          background-color: $primary;
          height: 3rem;
          width: 3rem;
          border-radius: $border-radius;
          color: white;
          cursor: pointer;
          justify-content: center;
          display: flex;
          align-items: center;
          transition: 400ms;
          &:hover {
            background-color: $dark;
          }
        }
      }
    }
  }
}

.area-header {
  height: 100px;
  min-height: 100px;
  border-bottom: 1px solid $gray-300;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  padding: 1rem;
}